import React, { useContext, useEffect, useState } from 'react';
import { graphql, useStaticQuery }                from 'gatsby';
import useScript                                  from 'react-script-hook';

import { findT }         from '@interness/web-core/src/components/_helpers';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import SEO               from '@interness/web-core/src/components/modules/SEO/SEO';
import { GlobalContext } from '@interness/web-core/src/providers/GlobalContext';


const RolexFrame = ({ apiKey, legalUrl, lang, page }) => {
  const { userConsents } = useContext(GlobalContext);
  const [frameInitialized, setFrameInitialized] = useState(false);
  const [loading] = useScript({
    src: 'https://corners.rolex.com/rlx-corner.js',
    checkForExisting: true
  });

  function rlx(wdow, docmnt, tag, cb, id, apiKey, legalUrl, lang, l) {
    const e = docmnt.getElementsByTagName(tag)[0];
    tag = docmnt.createElement(tag);
    const m = function (a) {
      delete wdow[cb];
      a(docmnt.getElementById(id), [apiKey, lang, legalUrl, l])
    };
    wdow[cb] = wdow[cb] || m;
    tag.async = !0;
    tag.src = 'https://corners.rolex.com/rlx-corner.js';
    e.parentNode.insertBefore(tag, e)
  }

  useEffect(() => {
    if (!loading) {
      rlx(window, document, 'script', 'rlxCornerCallback', 'rlx-corner', apiKey, legalUrl, lang, page)
      setFrameInitialized(true);
    }
    if (frameInitialized) {
      const rolexConsent = userConsents.find(consent => consent.function_id === 'rolex').consentGiven;
      const contentWindow = !!document.getElementById('rlx-corner').contentWindow;
      document.cookie = 'rlx-consent=' + rolexConsent + '; path=/'
      if (contentWindow) {
        console.log('set rlx consent', rolexConsent)
        document.getElementById('rlx-corner').contentWindow.postMessage(rolexConsent ? 'consentTrue' : 'consentFalse', 'https://corners.rolex.com/')
        document.cookie = 'rlx-consent=' + rolexConsent + '; path=/'
      }
    }

    //eslint-disable-next-line
  }, [loading, frameInitialized, userConsents]);

  return (
    <div>
      {loading && <div style={{ textAlign: 'center', position: 'relative' }}><p>Erfahren Sie mehr zu <Link
        to="https://www.rolex.com/de/watches.html">den Rolex Armbanduhren</Link></p></div>}
      <script id="rlx-corner"/>
    </div>
  )
}

const RolexModule = ({ modulePage }) => {
  const [hasMounted, setHasMounted] = useState(false);
  const data = useStaticQuery(query);
  const page = data.directusBrands.brand;
  const translations = findT(page.translations, 'de');
  const rolexInfo = data.directusBrandConfig.brand_config[0];
  const seoTitle = `${translations.seo_title ? translations.seo_title : page.display_name}`;

  useEffect(() => {
    setHasMounted(true);
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <SEO title={seoTitle}
           description={translations.seo_description ? translations.seo_description : null}
           lang={translations.language}/>
      <div>
        <noscript>
          <div style={{ textAlign: 'center' }}>
            <Spacer/>
            <p>Erfahren Sie mehr zu <Link to="https://www.rolex.com/de/watches.html">den Rolex Armbanduhren</Link></p>
          </div>
        </noscript>
        {hasMounted && <RolexFrame
          apiKey={rolexInfo.rolex_api_key}
          legalUrl={rolexInfo.rolex_legal_url}
          lang={'de'}
          page={modulePage}
        />}
      </div>
    </>
  )
};

const query = graphql`
    query {
        directusBrands(brand: {slug: {eq: "rolex"}, type: {type: {eq: "watches"}}}) {
            ...BrandsCore
            ...BrandHeaderImages
        }
        directusBrandConfig(id: {ne: "dummy"}) {
            brand_config {
                rolex_api_key
                rolex_legal_url
                rolex_version
            }
        }
        directusCompany {
            display_name
        }
    }
`;

export default RolexModule;
